<template>
  <div>
    <div
      ref="switch"
      :class="{ 'mdc-switch--checked': value }"
      class="mdc-switch"
    >
      <div class="mdc-switch__track" />
      <div class="mdc-switch__thumb-underlay">
        <div class="mdc-switch__thumb">
          <input
            :id="inputId"
            :checked="value"
            type="checkbox"
            class="mdc-switch__native-control"
            role="switch"
            @change="$emit('input', $event.target.checked)"
          />
        </div>
      </div>
    </div>
    <label :for="inputId" class="ml-2" v-text="label" />
  </div>
</template>

<script>
import { MDCSwitch } from '@material/switch';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    mdcSwitch: null,
  }),

  computed: {
    inputId() {
      return `switch-${this._uid}`;
    },
  },

  mounted() {
    this.mdcSwitch = new MDCSwitch(this.$refs.switch);
  },

  beforeDestroy() {
    this.mdcSwitch.destroy();
  },
};
</script>
