var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"font-light text-center mt-8 mx-4"},[_vm._v(" Choose a plan that's right for you ")]),_c('mdc-switch',{staticClass:"m-4 text-right",attrs:{"label":("Billed " + (_vm.yearly ? 'yearly' : 'monthly'))},model:{value:(_vm.yearly),callback:function ($$v) {_vm.yearly=$$v},expression:"yearly"}}),_c('div',{staticClass:"flex text-center flex-wrap"},_vm._l((_vm.plans),function(plan,index){return _c('div',{key:index,staticClass:"flex-1 m-4 p-4 bg-white rounded shadow-sm hover:shadow-lg",style:({ minWidth: '15rem' })},[_c('h2',{domProps:{"textContent":_vm._s(_vm.capitalize(plan.name))}}),_c('div',{staticClass:"text-primary my-8"},[_c('sup',{staticClass:"text-xl",style:({ top: '-1.2rem' })},[_vm._v("$")]),_c('span',{staticClass:"text-5xl",domProps:{"textContent":_vm._s(
            _vm.yearly && plan.yearly.rate > 0.1
              ? (plan.yearly.rate / 12).toFixed(2)
              : plan.monthly.rate
          )}}),_c('span',[_vm._v("/ month")])]),_c('p',{domProps:{"innerHTML":_vm._s(
          ("<b>" + (plan.limitations.emails_created.toLocaleString()) + "</b> emails created per month")
        )}}),(_vm.details)?[_c('p',{domProps:{"innerHTML":_vm._s(
            ("<b>" + (plan.limitations.contacts_account.toLocaleString()) + "</b> maximum contacts per account")
          )}}),_c('p',{domProps:{"innerHTML":_vm._s(
            ("<b>" + (plan.limitations.recipients_email) + "</b> max recipients per email")
          )}}),_c('p',{domProps:{"innerHTML":_vm._s(
            ("<b>" + (_vm.humanBytes(
              plan.limitations.attach_account
            )) + "</b> max attachments per account")
          )}}),_c('p',{domProps:{"innerHTML":_vm._s(
            ("<b>" + (_vm.humanBytes(
              plan.limitations.attach_email
            )) + "</b> max attachment per email")
          )}}),_c('p',{domProps:{"innerHTML":_vm._s(
            ("Send <b>" + (plan.limitations.max_later) + "</b> year" + (plan.limitations.max_later != 1 ? 's' : '') + " into the future")
          )}})]:_vm._e(),_c('p',[_c('mdc-button',{staticClass:"my-4",domProps:{"textContent":_vm._s(((_vm.details ? 'Less' : 'More') + " details"))},nativeOn:{"click":function($event){_vm.details = !_vm.details}}})],1),(_vm.buttons)?_c('p',[(
            _vm.$store.state.user.plan.name === plan.name &&
            _vm.$store.state.user.plan.billed === (_vm.yearly ? 'yearly' : 'monthly')
          )?_c('mdc-button',{attrs:{"disabled":"","raised":""}},[_vm._v(" Current Plan ")]):_c('mdc-button',{directives:[{name:"show",rawName:"v-show",value:(plan.monthly.rate > 0.1),expression:"plan.monthly.rate > 0.1"}],attrs:{"raised":"","primary":""},domProps:{"textContent":_vm._s('Choose')},nativeOn:{"click":function($event){return _vm.$emit('choice', { plan: plan, yearly: _vm.yearly })}}})],1):_vm._e()],2)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }