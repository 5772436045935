<template>
  <div>
    <h1 class="font-light text-center mt-8 mx-4">
      Choose a plan that's right for you
    </h1>
    <mdc-switch
      v-model="yearly"
      :label="`Billed ${yearly ? 'yearly' : 'monthly'}`"
      class="m-4 text-right"
    />
    <div class="flex text-center flex-wrap">
      <div
        v-for="(plan, index) in plans"
        :key="index"
        :style="{ minWidth: '15rem' }"
        class="flex-1 m-4 p-4 bg-white rounded shadow-sm hover:shadow-lg"
      >
        <h2 v-text="capitalize(plan.name)" />
        <div class="text-primary my-8">
          <sup :style="{ top: '-1.2rem' }" class="text-xl">$</sup>
          <span
            class="text-5xl"
            v-text="
              yearly && plan.yearly.rate > 0.1
                ? (plan.yearly.rate / 12).toFixed(2)
                : plan.monthly.rate
            "
          />
          <span>/ month</span>
        </div>
        <p
          v-html="
            `<b>${plan.limitations.emails_created.toLocaleString()}</b> emails created per month`
          "
        />
        <template v-if="details">
          <p
            v-html="
              `<b>${plan.limitations.contacts_account.toLocaleString()}</b> maximum contacts per account`
            "
          />
          <p
            v-html="
              `<b>${plan.limitations.recipients_email}</b> max recipients per email`
            "
          />
          <p
            v-html="
              `<b>${humanBytes(
                plan.limitations.attach_account
              )}</b> max attachments per account`
            "
          />
          <p
            v-html="
              `<b>${humanBytes(
                plan.limitations.attach_email
              )}</b> max attachment per email`
            "
          />
          <p
            v-html="
              `Send <b>${plan.limitations.max_later}</b> year${
                plan.limitations.max_later != 1 ? 's' : ''
              } into the future`
            "
          />
        </template>
        <p>
          <mdc-button
            class="my-4"
            @click.native="details = !details"
            v-text="`${details ? 'Less' : 'More'} details`"
          />
        </p>
        <p v-if="buttons">
          <mdc-button
            v-if="
              $store.state.user.plan.name === plan.name &&
              $store.state.user.plan.billed === (yearly ? 'yearly' : 'monthly')
            "
            disabled
            raised
          >
            Current Plan
          </mdc-button>
          <mdc-button
            v-else
            v-show="plan.monthly.rate > 0.1"
            raised
            primary
            @click.native="$emit('choice', { plan, yearly })"
            v-text="'Choose'"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/xhr';
import { capitalize, humanBytes } from '@/utils/string';
import MdcButton from '@/components/mdc/button.vue';
import MdcSwitch from '@/components/mdc/switch.vue';

export default {
  components: {
    MdcButton,
    MdcSwitch,
  },

  props: {
    buttons: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    yearly: true,
    details: false,
    plans: [],
  }),

  mounted() {
    axios.get('/plans').then((response) => {
      this.plans = response.data;
    });
  },

  methods: {
    capitalize,
    humanBytes,
  },
};
</script>
